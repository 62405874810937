export const colors = {
  bg: {
    primary: '#F7F1E9', //light beige
    secondary: '#B7D7EA', //pale sky blue
    tertiary: '#DCDCDC', //light gray
    quaternary: '#FBC96A', //orange
  },
  lbl: {
    quinary: '#FFA4BC',
  },
  text: {
    primary: '#4c4c4c',
    secondary: '#969696', //gray
    pink: '#FFA4BC', //light pink
  },
  tint: {
    secondary: '#56CCF2',
  },
  button: {
    primary: '#FFA4BC', //light pink
    secondary: '#AADA67', //light green
    tertiary: '#c4c4c4', //gray
    quaternary: '#FBC96A', //yellow
    quinary: '#DC6F66', //red
    septenary: '#56CCF2',
    disabled: '#ccc',
  },
  border: {
    primary: '#FFA4BC', //light pink
    secondary: '#B7D7EA', //light sky blue
    tertiary: '#DCDCDC', //light gray
    purple: '#C489CD',
  },
  twitter: '#2AA3EF',
} as const

export const font = {
  pcTitle: `font-size: 88px; font-weight: bold;`,
  pcH1: `font-size: 48px; font-weight: bold;`,
  pcH2: `font-size: 36px; font-weight: bold;`,
  pcH3: `font-size: 28px; font-weight: bold;`,
  pcSubtitle1: `font-size: 18px; font-weight: bold;`,
  pcSubtitle2: `font-size: 16px; font-weight: bold;`,
  pcArticle1: `font-size: 18px; font-weight: normal;`,
  pcArticle2: `font-size: 16px; font-weight: 200;`,
  pcDescription: `font-size: 18px; font-weight: normal;`,
  pcCaption: `font-size: 9px; font-weight: bold;`,
  spTitle: `font-size: 64px; font-weight: bold;`,
  pcLabel: `font-size: 14px; font-weight: bold`,
  spH1: `font-size: 32px; font-weight: bold;`,
  spH2: `font-size: 24px; font-weight: bold;`,
  spH3: `font-size: 18px; font-weight: bold;`,
  spSubtitle1: `font-size: 16px; font-weight: bold;`,
  spSubtitle2: `font-size: 18px; font-weight: bold;`,
  spArticle1: `font-size: 16px; font-weight: normal;`,
  spArticle2: `font-size: 14px; font-weight: 200;`,
  spDescription: `font-size: 14px; font-weight: normal;`,
  spCaption: `font-size: 9px; font-weight: bold;`,
  spLabel: `font-size: 12px; font-weight: bold`,
} as const

export const MAX_WIDTH = 400

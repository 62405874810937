import { Global, css } from '@emotion/core'
import emotionReset from 'emotion-reset'
import { colors } from './theme'

export const globalStyles = (
  <Global
    styles={css`
      ${emotionReset}
      * {
        box-sizing: border-box;
      }
      html,
      body {
        margin: 0;
        scroll-behavior: smooth;
        text-rendering: optimizeSpeed;
        min-height: 100vh;
        font-family: 'Roboto', 'Noto Sans JP', sans-serif;
        background-color: ${colors.bg.primary};
      }

      /* support iOS */
      /* アドレスバーの高さなどを省き、100vhを機能させる */
      /* https://coliss.com/articles/build-websites/operation/css/css-cover-the-entire-height-of-the-screen.html */
      html {
        height: -webkit-fill-available;
      }
      body {
        min-height: 100vh;
        min-height: -webkit-fill-available;
      }

      img {
        width: 100%;
        height: auto;
      }
      @font-face {
        font-family: 'ap';
        font-style: 'normal';
        font-weight: 500;
        src: url('/static/fonts/AP.ttf') format('truetype');
      }
      @font-face {
        font-family: 'anko';
        font-style: 'normal';
        font-weight: 500;
        src: url('/static/fonts/NuAnkoMochiCt-Reg.otf') format('opentype');
      }
      @font-face {
        font-family: 'smartfont';
        font-style: 'normal';
        font-weight: 500;
        src: url('/static/fonts/SmartFontUI.otf') format('opentype');
      }
    `}
  />
)

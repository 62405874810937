import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/performance'
import 'firebase/installations'
import firebaseConfig from '../../config/firebaseConfig'

let _app: firebase.app.App | null = null

export const getApp = () => {
  if (_app) return _app
  if (firebase.apps.length > 0) {
    _app = firebase.apps[0]
  } else {
    _app = firebase.initializeApp(firebaseConfig)
  }
  return _app
}

export const initFirebaseModules = () => {
  if (process.env.NODE_ENV !== 'development') {
    firebase.analytics()
    firebase.performance()
  }
}

export const getAnalytics = () => {
  return getApp().analytics()
}

export const getAuth = () => {
  return getApp().auth()
}

export const getDb = () => {
  return getApp().firestore()
}

export const getStorage = (url?: string) => {
  return getApp().storage(url)
}

export const getAppInstanceId = async () => {
  return await getApp().installations().getId()
}

export const loginWithTwitter = async () => {
  const provider = new firebase.auth.TwitterAuthProvider()
  try {
    await getAuth().signInWithRedirect(provider)
  } catch (error) {
    console.error(error)
  }
}

export const loginWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  try {
    await getAuth().signInWithRedirect(provider)
  } catch (error) {
    console.error(error)
  }
}

export const loginWithApple = async () => {
  const provider = new firebase.auth.OAuthProvider('apple.com')
  try {
    await getAuth().signInWithRedirect(provider)
  } catch (error) {
    console.error(error)
  }
}

export const logout = async () => {
  try {
    await getAuth().signOut()
  } catch (err) {
    console.error(err)
  }
}

export default {
  apiKey: 'AIzaSyCTh3ib3bb6V1_R3kCNDVXApzBiV3rd2jI',
  authDomain: 'gamee.games',
  databaseURL: 'https://nadare-production.firebaseio.com',
  projectId: 'nadare-production',
  storageBucket: 'nadare-production.appspot.com',
  messagingSenderId: '164662213762',
  appId: '1:164662213762:web:d8bca7c0b810aaada9c89b',
  measurementId: 'G-CXXBRFFW4J',
}
